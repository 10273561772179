import React, { useMemo } from 'react';
import { anchorSystemCategories } from '../../config/customConfig';
import moment from 'moment';
import css from './ListingPage.module.css';
import { InfoPopup } from '../../components';

function InfoBlock({ label, content, extraInfo, intl }) {
  return (
    <div className={css.infoBlock}>
      <span>
        {label}
        {extraInfo ? (
          <InfoPopup
            info={intl.formatMessage({
              id: 'EditListingFeaturesForm.waterDepthExtraInfo',
            })}
          />
        ) : (
          ''
        )}
      </span>
      <span>{content} </span>
    </div>
  );
}

function SectionDetailsMaybe({ description, publicData, intl }) {
  const {
    maxBoatSize,
    waterDepth,
    lastInspection: { date: lastInspectionDate } = {},
    anchorSystemCategories: anchorSystemKey,
    rules,
  } = publicData || {};

  const anchorSystem = useMemo(() => anchorSystemCategories.find(a => a?.key === anchorSystemKey), [
    anchorSystemKey,
  ]);

  const formattedLastInspection = lastInspectionDate
    ? moment(lastInspectionDate).format('Do MMM YYYY')
    : null;

  const feetLabel = intl.formatMessage({ id: 'ListingPage.feetLabel' });
  const infoBlocks = useMemo(
    () =>
      [
        {
          label: intl.formatMessage({ id: 'ListingPage.maxBoatSize' }),
          content: `${maxBoatSize} ${feetLabel}`,
        },
        {
          label: intl.formatMessage({ id: 'ListingPage.waterDepth' }),
          extraInfo: intl.formatMessage({ id: 'ListingPage.extraInfo' }),
          content: `${waterDepth} ${feetLabel}`,
        },
        formattedLastInspection && {
          label: intl.formatMessage({ id: 'ListingPage.lastInspectionLabel' }),
          content: formattedLastInspection,
        },
        {
          label: intl.formatMessage({ id: 'ListingPage.anchorType' }),
          content: anchorSystem?.label,
        },
      ].filter(Boolean),
    [maxBoatSize, waterDepth, formattedLastInspection, anchorSystem, intl]
  );

  return (
    <div className={css.moreInformation}>
      {infoBlocks.map((block, index) => (
        <InfoBlock
          key={index}
          label={block.label}
          content={block.content}
          extraInfo={block?.extraInfo}
          intl={intl}
        />
      ))}
      <div className={css.description}>
        <p>{description}</p>
      </div>
      {rules && (
        <div className={css.description}>
          <p>{rules}</p>
        </div>
      )}
    </div>
  );
}

export default SectionDetailsMaybe;
